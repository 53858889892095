import { Toast } from "vant";
import { getDevType } from "@/utils/index";

export const mixins = {
  data() {
    return {
      urlList: [
        {
          url: "https://d2hspj60mmd435.cloudfront.net/?dc=FNYTCS",
          iosUrl: "https://d2hspj60mmd435.cloudfront.net/?dc=FNYTCS",
          icon: require("./../assets/png/logo-pornhub.png"),
          name: "PornHub",
          time: 0,
        },
        {
          url: "https://d18dvjl5dqajrb.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d18dvjl5dqajrb.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-lld.jpg"),
          name: "萝莉岛",
          time: 0,
        },
        {
          url: "https://d1d4abgkiknem8.cloudfront.net/?dc=FNYTCS",
          iosUrl: "https://d1d4abgkiknem8.cloudfront.net/?dc=FNYTCS",
          icon: require("./../assets/png/logo-nut.png"),
          name: "NUT",
          time: 0,
        },
        {
          url: "https://d10qstglhl5tyz.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d10qstglhl5tyz.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-haijiao.png"),
          name: "海角",
          time: 0,
        },
        {
          url: "https://d2zpwdjhp8jlfd.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d2zpwdjhp8jlfd.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-yjmh.png"),
          name: "妖精",
          time: 0,
        },
        {
          url: "https://d2z5svoivmju5m.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d2z5svoivmju5m.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-haose.png"),
          name: "好色",
          time: 0,
        },
        {
          url: "https://d34ass4lgd9uh7.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d34ass4lgd9uh7.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-pf.png"),
          name: "泡芙",
          time: 0,
        },
        {
          url: "https://d20u0upv9zzlmi.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d20u0upv9zzlmi.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-lieqi.png"),
          name: "91猎奇",
          time: 0,
        },
        {
          url: "https://d2bgnsqruqwjfl.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d2bgnsqruqwjfl.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-qiyou.png"),
          name: "妻友",
          time: 0,
        },
        {
          url: "https://d3599zftnjzxye.cloudfront.net?dc=FNYTCS",
          iosUrl: "https://d3599zftnjzxye.cloudfront.net?dc=FNYTCS",
          icon: require("./../assets/png/logo-anwang.png"),
          name: "91暗网",
          time: 0,
        },
      ], // 域名数组
      ping: 1,
      timer: null,
      showImg: true,
      addressUrl: "cao.gg",
    };
  },
  mounted() {
    this.addressUrl = window.location.host;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        this.ping++;
      }, 100);
    },
    autoTest(item, index) {
      if (this.ping <= 10) {
        item.time = this.ping * 10;
      } else {
        item.time = this.ping * 100;
      }
      if (index == this.urlList.length - 1) {
        clearInterval(this.timer);
      }
    },
    jumpUrl(item) {
      if (getDevType() == "android") {
        window.open(item.url);
      } else {
        window.open(item.iosUrl);
      }
    },
    jumpUrl2(url) {
      window.open(url);
    },
    reset() {
      this.urlList = this.urlList.map((item) => {
        item.time = 0;
        return item;
      });
      this.showImg = false;
      this.$nextTick(() => {
        this.showImg = true;
      });
      this.ping = 1;
      this.init();
    },
    doCopy() {
      let req = this.addressUrl;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
